<template>
  <d-card-footer class="pagination" style="display: flex; align-items: center;">
    <div class="pagination__text">
      {{ paginationText }}
    </div>

    <div class="pagination__right">

      <span class="pagination__per-page-text">{{ $t('pagination.per_page') }} </span>
      <d-form-select v-model="perPageValue" :options="perPageOptions" @change="setPerPage" />

      <d-button-group size="small" v-if="totalPages > 1">
        <d-button v-if="(currentPage > 1)" @click="setPage(currentPage - 1)"> &lt; </d-button>
        <d-button v-else disabled> &lt; </d-button>

        <template v-if="totalPages > 1">
          <template v-for="paginateIndex in paginationButtons">
            <d-button v-if="paginateIndex !== null"
                      :key="paginateIndex"
                      :class="{'active': currentPage === paginateIndex }"
                      @click="setPage(paginateIndex)">{{paginateIndex}}</d-button>
            <d-button :key="paginateIndex" v-else disabled>...</d-button>
          </template>
        </template>

        <d-button v-if="(currentPage < totalPages)" @click="setPage(currentPage + 1)"> &gt; </d-button>
        <d-button v-else disabled> &gt; </d-button>
      </d-button-group>
    </div>
  </d-card-footer>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    amountOfItems: Number,
    currentPage: Number,
    perPage: Number,
    totalPages: Number,
  },

  data() {

    return {
      perPageValue: this.perPage,
      perPageOptions: [
        { value: 10, text: '10' },
        { value: 20, text: '20' },
        { value: 40, text: '40' },
        { value: 60, text: '60' },
      ]
    };
  },

  computed: {

    paginationButtons() {

      if(this.totalPages <= 7) {
        const items = [];
        for(let i = 1; i <= this.totalPages; i ++) items.push(i);
        return items;
      }

      if(this.currentPage <= 3) return [1,2,3,4,5,null,this.totalPages];
      if(this.currentPage >= this.totalPages - 2) return [1,null,(this.totalPages - 4),(this.totalPages - 3),(this.totalPages - 2),(this.totalPages - 1),this.totalPages];
      return [1, null,(this.currentPage - 1),this.currentPage,(this.currentPage + 1),null,this.totalPages];
    },

    paginationText() {

      const firstItemIndex = this.perPage * (this.currentPage - 1) + 1;
      let lastItemIndex = this.perPage * this.currentPage;
      if(lastItemIndex > this.amountOfItems) lastItemIndex = this.amountOfItems;

      return this.$t('pagination.text', [firstItemIndex, lastItemIndex, this.amountOfItems])
    }
  },
  methods: {

    setPage: function (page) {
      this.$emit('goToPage', page);
    },

    setPerPage: function (perPageValue) {
      this.$emit('setPerPage', perPageValue);
    },

  },
}
</script>
