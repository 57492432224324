<template>
  <div class="app__loading">
    <div class="app__loading-wrapper">
      <div class="app__loading-message">
        <loading  />
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "./loading";
export default {
  name: "AppLoading",
  components: {Loading}
}
</script>
