<template>
  <section>

    <app-loading v-if="!orders_loaded" />

    <d-row align-h="between" align-v="center" :no-gutters="true">

      <d-col class="table__header" :sm="12" :md="3">
        <div class="app__title">{{ $t("order_table.overview") }}</div>
        <div class="app__subtitle" v-if="provider">{{ $t("order_table.title") }}: {{ provider.name }}</div>
      </d-col>

      <d-col class="table__filter  table__filter--type" :sm="6" :md="2">
        <span class="table__filter-label">{{ $t("order_table.order_by") }}:</span>
        <d-select v-model="orderBy" :options="orderByOptions"></d-select>
      </d-col>

      <d-col class="table__filter  table__filter--type" :sm="6" :md="2">
        <span class="table__filter-label">{{ $t("order_table.status") }}:</span>
        <d-select v-model="statusFilter" :options="statusOptions"></d-select>
      </d-col>

      <d-col class="table__filter  table__filter--type" :sm="6" :md="4">
        <span class="table__filter-label">{{ $t("order_table.search_by") }}:</span>
        <d-select v-model="filterType" :options="filterTypeOptions"></d-select>
        <d-input name='searchField' type="text" :placeholder="$t('order_table.search') + '...'" v-model="searchValue"></d-input>
      </d-col>

    </d-row>

    <template v-if="this.orders.length > 0">
      <table class="table  table-hover  table__main  sp-mt-3">
        <thead class="table__thead">
        <tr>
          <th  class="border-0 col-1"><span class="sortable">{{ $t("order_table.column_title.reference") }}</span></th>
          <th  class="border-0 col-1"><span>{{ $t("order_table.column_title.status") }}</span></th>
          <th  class="border-0 col-1"><span class="sortable">{{ $t("order_table.column_title.created_at") }}</span></th>
          <th  class="border-0 col-1"><span class="sortable">{{ $t("order_table.column_title.arrival") }}</span></th>
          <th  class="border-0 col-1"><span class="sortable">{{$t("order_table.column_title.departure") }}</span></th>
          <th  class="border-0 col-2"><span class="sortable">{{ $t("order_table.column_title.name") }}</span></th>
          <th  class="border-0 col-2"><span class="sortable">{{ $t("order_table.column_title.email") }}</span></th>
          <th  class="border-0 col-1"><span class="sortable">{{ $t("order_table.column_title.license") }}</span></th>
          <th  class="border-0 col-1"><span class="sortable">{{ $t("order_table.column_title.price") }}</span></th>
          <th  class="border-0 col-1"><span class="sortable">  {{ $t("form_fields.country") }}</span></th>
         
        </tr>
        </thead>

        <tbody>
        <template v-for="order in orders">
          <tr :key="order.id" @click="onSelectOrder(order, $event)">
            <td class="col-1">
              {{order.reference}} 
            </td>
            <td class="col-1">
              <d-badge :class="statusLabel(order.status)">{{order.status}}</d-badge>
            </td>
            <td class="col-1">{{dateFormat(order.createdAt)}}</td>
            <td class="col-1">{{dateFormat(order.from)}}</td>
            <td class="col-1">{{dateFormat(order.to)}}</td>
            <td class="col-2">
              {{order.lastName}}, {{order.firstName}}<br>
            </td>
            <td class="col-2">
              {{order.email}}<br>
            </td>
            <td class="col-1">
              {{order.journey.car.license_plate}}
            </td>
            <td class="col-1">{{ priceFormat(order.price.amount,order.price.currency)}}</td>
            <td class="col-1">{{ order.language.toUpperCase() }}</td>
            <td data-column="voucher" class="u-text-center">
             <a class="table__voucher" v-if="isset(order.voucher) && !empty(order.voucher.url)" :href="order.voucher.url"><i class="material-icons mr-2" style="font-size: 18px;">sim_card_download</i></a>
             <span v-else >&#45;&#45;</span>
            </td>
           

          </tr>
        </template>
        </tbody>
      </table>

      <pagination
        v-on:goToPage="goToPage($event)"
        v-on:setPerPage="setPerPage($event)"
        v-if="ordersCount > 0"
        ref="pagination"
        :amountOfItems="ordersCount"
        :totalPages="pagesCount"
        :currentPage="currentPage"
        :perPage="perPage" >
      </pagination>
    </template>
    <div v-else class="table__no-orders sp-mt-2">
      {{ $t("order_table.no_orders") }}
    </div>

  </section>
</template>

<script>
import {mapProviderStateFields, } from "~/store";
import graphqlCalls from "~/graphql";
import mixins from "~/mixins";
import {providerOrderSettings} from "~/graphql/providers/GetProviderOrders";
import Pagination from "~/components/Pagination";
import AppLoading from "@/components/AppLoading";

export default {
  name: "OrdersIndex",
  components: {AppLoading, Pagination},
  mixins: [graphqlCalls, mixins],

  data() {
    return {
      orders: [],
      orders_loaded: false,

      orderByOptions: [
        {
          text: this.$t('order_order_by_options.created_at_desc'),
          value: 'created_at_desc'
        },
        {
          text: this.$t('order_order_by_options.created_at_asc'),
          value: 'created_at_asc'
        },
        {
          text: this.$t('order_order_by_options.from_desc'),
          value: 'from_desc'
        },
        {
          text: this.$t('order_order_by_options.from_asc'),
          value: 'from_asc'
        },
        {
          text: this.$t('order_order_by_options.to_desc'),
          value: 'to_desc'
        },
        {
          text: this.$t('order_order_by_options.to_asc'),
          value: 'to_asc'
        },
      ],

      statusOptions: [
        // {
        //   text: this.$t('order_statuses.undetermined'),
        //   value: 'UNDETERMINED'
        // },
        {
          text: this.$t('order_statuses.completed'),
          value: 'COMPLETED'
        },
        {
          text: this.$t('order_statuses.cancelled'),
          value: 'CANCELLED'
        },
      ],
      filterTypeOptions: [
        {
          text: this.$t('order_table.filters.reference'),
          value: 'reference'
        },
        {
          text: this.$t('order_table.filters.email'),
          value: 'email'
        },
        {
          text: this.$t('order_table.filters.language'),
          value: 'language'
        },
        {
          text: this.$t('order_table.filters.licensePlate'),
          value: 'licensePlate'
        },
      ],
      orderBy: 'created_at_desc',
      statusFilter: 'COMPLETED',
      filterType: 'reference',
      searchValue: "",

      ordersCount: 0,
      pagesCount: 1,
      currentPage: 1,
      perPage: 20,
    };
  },

  computed: {
    ...mapProviderStateFields([
      'active_provider_index',
      'providers',
    ]),

    provider : function () {
      if(this.providers.length === 0) return null
      return this.providers[this.active_provider_index];
    },
  },

  created() {
    if(this.$route.query.type !== undefined) this.filterType = this.$route.query.type;
    if(this.$route.query.status !== undefined) this.statusFilter = this.$route.query.status.toUpperCase();
    if(this.$route.query.search !== undefined) this.searchValue = this.$route.query.search;
    if(this.$route.query.perPage !== undefined) this.perPage = parseInt(this.$route.query.perPage);
    if(this.$route.query.page !== undefined) this.currentPage = parseInt(this.$route.query.page);
    if(this.$route.query.orderBy !== undefined) this.orderBy = this.$route.query.orderBy;
  },

  mounted() {
    if(this.provider)  this.loadServiceLocations(this.provider.id)
  },

  watch: {
    provider: function (val) {
      if(!val) return;
      this.loadServiceLocations(val.id)
     // this.loadOrders(val.id);
    
    },
    statusFilter: function () {

      const query = {...this.$route.query}

      if(this.statusFilter === 'COMPLETED') delete query.status
      else query.status = this.statusFilter.toLowerCase()

      this.$router.replace({path: this.$route.path, query: query})
      if(this.provider) this.loadServiceLocations(this.provider.id)
    
    },
    orderBy: function () {

      const query = {...this.$route.query}
      query.orderBy = this.orderBy

      this.$router.replace({path: this.$route.path, query: query})
      if(this.provider)  this.loadServiceLocations(this.provider.id)
     
    },
    searchValue: function () {
      this.updateSearchableFilter()
      if(this.provider)  this.loadServiceLocations(this.provider.id)
     
    },
    filterType: function () {
      this.updateSearchableFilter()
      if(this.provider)this.loadServiceLocations(this.provider.id)
      
    },
  },

  methods: {
    loadServiceLocations: async function (providerId) {
    
    let serviceLocationsIds = []
    await this.fetchServiceLocations(providerId,this.date).then((response) => {
        response.data.provider.serviceLocations.forEach((serviceLocation)=>{
          serviceLocationsIds.push(serviceLocation.id)
          
        })
  })

  if(serviceLocationsIds.length > 0) {
    this.loadOrders(serviceLocationsIds)
    
  }
},
    loadOrders: function (serviceLocationsIds) {

      const self = this;
      this.orders_loaded = false
      const settings = this.deepClone(providerOrderSettings);

      if(this.statusFilter !== 'UNDETERMINED') settings.filters.status = this.statusFilter
      if(this.searchValue !== '') settings.filters[this.filterType] = this.searchValue

      switch (this.orderBy) {

        case 'created_at_asc':
          settings.sorting.sortBy = 'created_at';
          settings.sorting.direction = 'Ascending';
          break;

        case 'from_desc':
          settings.sorting.sortBy = 'from';
          settings.sorting.direction = 'Descending';
          break;

        case 'from_asc':
          settings.sorting.sortBy = 'from';
          settings.sorting.direction = 'Ascending';
          break;

        case 'to_desc':
          settings.sorting.sortBy = 'to';
          settings.sorting.direction = 'Descending';
          break;

        case 'to_asc':
          settings.sorting.sortBy = 'to';
          settings.sorting.direction = 'Ascending';
          break;


        default:
          settings.sorting.sortBy = 'created_at';
          settings.sorting.direction = 'Descending';
          break;
      }

      settings.pagination.limit = this.perPage;
      settings.pagination.page = this.currentPage;
      settings.filters.service_locations_ids = serviceLocationsIds

      this.fetchOrders  (settings).then((response) => {
        self.orders = response.data.orders ?? []
        
        self.orders_loaded = true
      })
      this.fetchOrdersCount  ( settings).then((response) => {
        self.ordersCount = response.data.ordersCount
        self.pagesCount = Math.ceil(response.data.ordersCount/this.perPage)
    
      })

    },

    goToPage: function (page) {
      this.currentPage = page

      const query = {...this.$route.query}
      query.page = page
      this.$router.replace({path: this.$route.path, query: query});
      this.loadServiceLocations(this.provider.id)
     // this.loadOrders(this.provider.id)
    },

    setPerPage: function (perPageValue) {
      this.perPage = perPageValue

      const query = {...this.$route.query}
      query.perPage = perPageValue

      // Remove page prop, and reset currentPage to 1
      delete query.page
      this.currentPage = 1

      this.$router.replace({path: this.$route.path, query: query});
      this.loadServiceLocations(this.provider.id)
      //this.loadOrders(this.provider.id)
    },

    updateSearchableFilter: function () {
      const query = {...this.$route.query}

      if(this.searchValue === '') {
        delete query.type
        delete query.search
      }
      else {
        query.type = this.filterType
        query.search = this.searchValue
      }

      this.$router.replace({path: this.$route.path, query: query});
    },

    onSelectOrder(order, event) {

      const td = event.target.closest('td')
      const tdColumn = (td !== null) ? td.dataset.column : undefined

      // Return when click on voucher column
      if(td && tdColumn && tdColumn === 'voucher') return

      this.$router.push({name: 'orders.show', params: {id: order.id}})
    }
  }
};
</script>
