<template>
  <div class="loading-message__wrapper">
    <span class="loading-message">
      <img alt="loading" height="50" width="50" :src="require('@/assets/img/loading.gif')" />
      <span class="loading-message__text">{{ $t('feedback.loading') }}</span>
      <span class="loading-message__dot"></span>
      <span class="loading-message__dot"></span>
      <span class="loading-message__dot"></span>
    </span>
  </div>
</template>

<script>
export default {
  name: "loading"
}
</script>